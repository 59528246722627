import { LoaderFunction, redirect } from 'react-router-dom';
import { PATH_ERROR, PATH_HOME } from '../constants/routes';
import { ApiAuthenticationError, ApiAuthorizationError, ApiBadRequestError, ApiClient, ApiNotFoundError, NoActiveAccountError } from '../utils/api';


const apiClient = new ApiClient();

const getErrorRedirectPath = (error: any): string => {
  if (error instanceof ApiBadRequestError) {
    return PATH_ERROR.error400;
  } else if (error instanceof ApiAuthenticationError) {
    return PATH_ERROR.error401;
  } else if (error instanceof ApiAuthorizationError) {
    return PATH_ERROR.error403;
  } else if (error instanceof ApiNotFoundError) {
    return PATH_ERROR.error404;
  } else if (error instanceof NoActiveAccountError) {
    return PATH_HOME.root;
  } else {
    console.error('Error in chatLoader:', error);
    return PATH_ERROR.error500;
  }
};

export const loader: LoaderFunction = async ({ request }) => {
  try {
    const [skillDataArray, userInfo, versionData] = await Promise.all([
      apiClient.getAvailableSkills(),
      apiClient.getUserInfo(),
      apiClient.getApiVersion(),
    ]);
    return { skillDataArray, userInfo, versionData };
  } catch (error: any) {
    const redirectPath = getErrorRedirectPath(error);
    return redirect(redirectPath);
  }
};
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { initializeIcons } from '@fluentui/react/lib/Icons'; 
import { mergeStyles } from '@fluentui/react/lib/Styling';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance, msalInitPromise } from "./msal";
// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

initializeIcons();

msalInitPromise.then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

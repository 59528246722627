import { ChatApp } from "../../components/ChatApp"
import { useLoaderData } from 'react-router-dom';
import { SkillData, UserInfo, VersionData } from '../../utils/api';

interface ChatLoaderData {
  skillDataArray: SkillData[];
  userInfo: UserInfo; 
  versionData: VersionData; 
}

export const ChatPage = () => {
  const loaderData = useLoaderData() as ChatLoaderData;
  const { skillDataArray, userInfo, versionData } = loaderData;
  return <ChatApp skillDataArray={skillDataArray} userInfo={userInfo} versionData={versionData} />;
};
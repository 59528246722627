import { useContext } from 'react';
import { Stack, Text, IContextualMenuProps } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import { SelectedSkillContext } from '../contexts/SelectedSkillContext';
import { FluentProvider, webLightTheme, makeStyles, shorthands, Button } from "@fluentui/react-components";
import { UserInfo, VersionData } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH } from '../constants/routes';
import { MsHeader } from './MsHeader';

const useStyles = makeStyles({
  provider: {
    backgroundColor: '#FAFAFA',
    width: "100%",
  },
  main: {
    ...shorthands.gap("48px"),
    display: "flex",
    marginBottom: "2rem",
  },
  button: {
    backgroundColor: '#174AE4',
    width: '14rem',
    height: '3.125rem',
    paddingLeft: '2.62rem',
    paddingRight: '2.62rem',
    paddingTop: '0.81rem',
    paddingBottom: '0.81rem',
    fontSize: '1rem',
    marginRight: '2rem',
    "@media (max-width: 576px)": {
      marginRight: '0.5rem',
      width: '12rem',
      paddingLeft: '0rem',
      paddingRight: '0rem',
    }
  },

  card: {
    width: "100%",
    maxWidth: "100%",
    height: "fit-content",
    paddingLeft: '24px',
    paddingTop: '12px',
  }
});

export interface INavigationBarProps {
  scenario: 'wait_select_skill' | 'skill_selected' | 'conversation';
  onContactEPSOSMEClick: () => void;
  versionData: VersionData; 
  userInfo: UserInfo; 
}

export const NavigationBar = ({ scenario, onContactEPSOSMEClick, versionData, userInfo }: INavigationBarProps) => {
  const styles = useStyles();
  const selectedSkill = useContext(SelectedSkillContext);
  const navigate = useNavigate();
  
  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'signOut',
        text: 'Sign Out',
        onClick: () => navigate(PATH_AUTH.logout, { replace: true }),
        iconProps: { iconName: 'SignOut' },
      },
      {
        key: 'version',
        text: 'Version: ',
        disabled: true,
        style: { fontSize: '0.8rem' },
        iconProps: { iconName: 'Info' },
      },
    ],
    directionalHintFixed: true,
  };
  menuProps.items[1].text = 'Version: ' + versionData?.version
  
  const hasUserInfo = userInfo && Object.keys(userInfo).length > 0;
  
  return (
    <MsHeader>
      {hasUserInfo && (
        <Stack horizontal>
          {(scenario === 'skill_selected' || scenario === 'conversation') && (
          <Stack
            horizontal
            verticalAlign="start">
            <FluentProvider theme={webLightTheme} className={styles.provider}>
              <Button
                className={styles.button}
                shape="circular"
                appearance="primary"
                as="a"
                target="_blank"
                href={selectedSkill.contact_url}
                onClick={() => onContactEPSOSMEClick()}
              >
                Contact EPSO SME
              </Button>
            </FluentProvider>
          </Stack>
          )}
          <Stack
            id="user-btn"
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: '0.81rem' }}
            styles={{
              root: {
                // backgroundColor: 'white',
                marginRight: '2.5rem',
              },
            }}
          >
            <Text styles={{
              root: {
                height: '40px',
                fontSize: '1.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                "@media (max-width: 576px)": {
                  fontSize: '1rem',
                  display:'none'
                }
              }
            }}>
              {userInfo?.displayName}
            </Text>
            <IconButton 
              menuProps={menuProps}
              onRenderMenuIcon={() => null}
              iconProps={{ iconName: 'Contact', style: { fontSize: '2.2rem' } }}
              style={{
                borderRadius: '50%',
                backgroundColor: 'gray',
                color: 'white',
                width: '2.87rem',
                height: '2.87rem',
              }}
              title={userInfo?.displayName}
              ariaLabel="User"
            >
            </IconButton>
          </Stack>
        </Stack>
      )}
    </MsHeader>
  );
};

import { useMsal } from "@azure/msal-react";
import { Home } from "../../components/Home";
import { APP_TITLE } from "../../constants/env";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_CHAT, PATH_ONBOARDING } from "../../constants/routes";
import { ApiClientContext } from "../../contexts/ApiClient";
import { ApiClient } from "../../utils/api";
import Loading from "../../components/Loading";


export const HomePage = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const apiClient = useContext(ApiClientContext) as ApiClient;
  // Waiting for handleRedirectPromise to complete
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    // Handle redirect from MSAL
    const handleRedirect = async () => {
      const response = await instance.handleRedirectPromise();
      if (response) {
        setLoading(true);
        instance.setActiveAccount(response.account);
        // Parse the state to get the onboarding code
        const state = response.state ? JSON.parse(response.state) : {};
        const onboardingCode = state.onboardingCode;
        // Handle onboarding (This redirect is triggered by the onboarding flow)
        if (onboardingCode) {
          navigate(PATH_ONBOARDING.withCode(onboardingCode));
        } else {
          // Normal login
          navigate(PATH_CHAT.root, { replace: true });
        }
      }
      // If the user is already logged in, redirect to the chat page
      const activeAccount = instance.getActiveAccount();
      if (activeAccount) {
        navigate(PATH_CHAT.root, { replace: true });
        return;
      }else{
        setLoading(false);
      }
    };
    handleRedirect();
  }, [apiClient, instance, navigate]);
  
  if (loading) {
    return <Loading appTitle={APP_TITLE} />;
  }
  return (
    <Home appTitle={APP_TITLE} />
  );
};

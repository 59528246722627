import React from 'react';
import { MsHeader } from './MsHeader';
// Use Fluent2 instead of Fluent1
import { 
  Spinner, 
  Text, 
  makeStyles, 
  FluentProvider, 
  webLightTheme,
  shorthands, 
} from '@fluentui/react-components';

interface LoadingProps {
  appTitle?: string; 
}

const useStyles = makeStyles({
  provider: {
    backgroundColor: '#FAFAFA',
    ...shorthands.overflow('hidden'),
  },
  headerText: {
    fontWeight: 600,
    fontSize: '4.5rem',
    lineHeight: 'normal',
    marginBottom: '3rem',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
  spinnerLabel: {
    color: '#000000',
    fontSize: '1.2rem',
    marginTop: '16px',
    fontFamily: 'Roboto, sans-serif', 
    letterSpacing: '0.05rem',
  },
});

const Loading: React.FC<LoadingProps> = ({ appTitle }) => {
  const styles = useStyles();  
  return (
    <FluentProvider theme={webLightTheme} className={styles.provider}>
      <MsHeader />
      <div className={styles.loadingContainer}>
        <Text className={styles.headerText}>{appTitle}</Text>
        <Spinner size="large" />
        <Text className={styles.spinnerLabel}>
          Loading...
        </Text>
      </div>
    </FluentProvider>
  );
};

export default Loading;
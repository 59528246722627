import { Stack, Text, Link } from '@fluentui/react';
import { FAQ } from './FAQ';
import { Terms } from './Terms';
import { useState } from 'react';
import { FEEDBACK_URL, PRIVACY_URL } from '../constants/env';

export const Footer = () => {
  const [activeModal, setActiveModal] = useState<'terms' | 'faq' | null>(null);
  const handleTermsClick = () => setActiveModal('terms');
  const handleFAQClick = () => setActiveModal('faq');
  const handleOnClose = () => setActiveModal(null);

  return (
    <Stack>
      <Text styles={{ root: { fontSize: '1.25rem', textAlign: 'center' } }}>
        <Link target="_blank" href={FEEDBACK_URL}>Feedback</Link> |{' '}
        <Link onClick={() => { if (handleTermsClick !== undefined) { handleTermsClick() } }}>Terms</Link> |{' '}
        <Link target="_blank" href={PRIVACY_URL}>Privacy</Link> |{' '}
        <Link onClick={() => { if (handleFAQClick !== undefined) { handleFAQClick() } }}>Responsible AI FAQ</Link>
      </Text>
      <Terms open={activeModal === 'terms'} onClose={handleOnClose} />
      <FAQ open={activeModal === 'faq'} onClose={handleOnClose} />
    </Stack>
  );
};
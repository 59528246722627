import React, { useEffect } from 'react';
import { ApiClient } from './utils/api'
import { ApiClientContext } from './contexts/ApiClient';
import { RouterProvider } from 'react-router-dom';
import routes from './routes/routes';
import { APP_TITLE, API_PREFIX } from './constants/env';
import Loading from './components/Loading';

const apiClient = new ApiClient(API_PREFIX);

export const App: React.FunctionComponent = () => {
  useEffect(() => {
    // Set the document title
    document.title = APP_TITLE;
  }, [])

  return (
    <ApiClientContext.Provider value={apiClient}>
      <RouterProvider router={routes} fallbackElement={<Loading appTitle={APP_TITLE} />}/>
    </ApiClientContext.Provider>
  );
};
